<template lang="pug">
    Dialog(:showHeader="false" :visible.sync="show" @keydown.esc="$emit('close')" modal :closable="false"
        :style="{minWidth: '40vw', maxWidth: '90%'}" :contentStyle="{overflowY: 'auto !important'}")
        form(@submit.prevent="handleSubmit()")
            .p-fluid
                h3.text-title.ta-center.text-primary.mt-0 Resetar senha
                .p-field.p-col-12
                    label <strong>Nome</strong>
                    div.p-inputtext.p-component.p-disabled {{dadosUsuario.nm_pessoa_fisica}}
                .p-field.p-col-6
                    label <strong>Usuário</strong>
                    div.p-inputtext.p-component.p-disabled {{dadosUsuario.cd_usuario_django.username}}
                .p-field.p-col-12(:class="{ 'form-group--error': submitted && $v.form.novaSenha.$error }")
                    label(for='nova_senha') <strong>Nova Senha:</strong>
                    Password#nova_senha(v-model="$v.form.novaSenha.$model" :feedback="false")
                    .feedback--errors(v-if='submitted && $v.form.novaSenha.$error')
                        .form-message--error(v-if="!$v.form.novaSenha.required") Senha é obrigatória.

            .ta-right
                .px-4(v-if='waiting')
                    ProgressSpinner.waitingNomeUsuario(strokeWidth='4' style="width:30px;height:30px")
                .px-2(v-else)
                    Button.p-button-secondary.mr-2(label="Cancelar" icon="pi pi-times" @click="show=false")
                    Button.p-button-info(label="Salvar" icon="pi pi-check" type="submit")
</template>

<style scoped>

</style>

<script>
import Button from 'primevue/button'
import Dialog from 'primevue/dialog'
import Password from 'primevue/password'
import ProgressSpinner from 'primevue/progressspinner'
import Usuario from "@/middleware/controllers/Usuario"
import { required, minLength } from 'vuelidate/lib/validators'

export default {
    components: { Button, Dialog, Password, ProgressSpinner },
    props: ['display','data'],
    computed: {
        show: {
            get() {
                return this.display
            },
            set(value) {
                if (!value) {
                    this.$emit('close')
                }
            }
        }
    },
    data() {
        return {
            waiting: false,
            submitted: false,
            dadosUsuario: [],
            form: {
                novaSenha: '',
            }
        }
    },
    validations: {
        form: {
            novaSenha: { required, minLength: minLength(6) },
        }
    },
    created(){
        this.dadosUsuario = this.data.data;
        console.log(this.dadosUsuario)
    },
    methods: {
        handleSubmit() {
            this.submitted = true

            this.$v.$touch()
            if (this.$v.$invalid) return 0

            this.waiting = true
            console.log(this.dadosUsuario.cd_usuario_django)
            console.log(this.form)
            Usuario.resetPassword(this.dadosUsuario.cd_usuario_django.id, this.form.novaSenha)
                .then(response => {
                    this.waiting = false
                    if ([200, 201, 204].includes(response.status)) {
                        this.$toast.info('Senha alterada com sucesso', { duration: 3000 })
                        this.show = false
                    } else if ([400, 401, 403].includes(response.status)) {
                        if (response.data.detail) {
                            if (typeof response.data.detail == 'string')
                                this.$toast.error(response.data.detail, { duration: 3000 })
                            else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
                        }
                    }
                })
        }
    }
}
</script>
