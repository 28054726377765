<template lang="pug">
.main-wrapper.paciente-listar
		header.main-wrapper-header
			.p-grid.p-align-center
				.p-col-12.ta-right
					h1.text-header.text-secondary MedClub / <b>Pacientes</b>

		Dialog.dialogApagar(header='Remover paciente' :visible.sync='dialogApagar' :modal='true')
			p Deseja remover o paciente <b>{{ dialogApagar_data.nm_pessoa_fisica }}</b>?
			.ta-center.my-4
				ProgressSpinner(v-if='waitingApagar' strokeWidth='6')
				Button.p-button-danger(v-else label='Remover' @click='remove()')

		Panel.mb-2(header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576')
			.p-grid.p-fluid.p-align-end

				.p-col-12.p-md-2
					label.form-label Código:
					.p-inputgroup
						InputNumber(
							placeholder='Código'
							:autoClear='false'
							@keyup.enter.native='applyFilters()'
							@input='checkEmptyField("id")'
							v-model='filters.id'
							:min=0
							:max=9999999
						)
						Button(icon='jam jam-search' @click='applyFilters()')

				.p-col-12.p-md-2
					label.form-label CPF:
					.p-inputgroup
						InputMask(
							mask='999.999.999-99'
							placeholder='CPF'
							:autoClear='false'
							v-model='filters.nr_cpf'
							@keydown.enter.native='applyFilters()'
						)
						Button(icon='jam jam-search' @click='applyFilters()')

				.p-col-12.p-md-3
					label.form-label Nome:
					.p-inputgroup
						InputText(
							placeholder='Nome'
							:autoClear='false'
							@keyup.enter.native='applyFilters()'
							@input='checkEmptyField("nm_pessoa_fisica")'
							v-model='filters.nm_pessoa_fisica'
						)
						Button(icon='jam jam-search' @click='applyFilters()')

				.p-col-12.p-md-2
					label.form-label Data de nascimento:
					.p-inputgroup
						InputMask(
							mask='99/99/9999'
							placeholder='Data de nascimento'
							:autoClear='false'
							@keydown.enter.native='applyFilters()'
							v-model='filters.dt_nascimento'
						)
						Button(icon='jam jam-search' @click='applyFilters()')

				.p-col-12.p-md-3
					label.form-label E-mail:
					.p-inputgroup
						InputText(
							placeholder='E-mail'
							:autoClear='false'
							@keyup.enter.native='applyFilters()'
							@input='checkEmptyField("ds_email")'
							v-model='filters.ds_email'
						)
						Button(icon='jam jam-search' @click='applyFilters()')

				.p-col-12.p-md-3.p-lg-2
					label.form-label Telefone:
					.p-inputgroup
						InputMask(
							mask='(99) 99999-999?9'
							type='tel'
							v-model='filters.nr_telefone'
							@keydown.enter.native='applyFilters()'
							:autoClear="false")
						Button(icon='jam jam-search' @click='applyFilters()')

		.ta-right.my-2
			Button(label='Adicionar' icon="jam jam-plus" @click="$router.push('/paciente/salvar/0/')")

		ProgressBar(v-if='waiting' mode="indeterminate")
		div(v-else-if='list.length == 0')
			p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.
		div(v-else)
			DataView.dataview.my-2(:value="list" layout="grid")
				template(#grid="props")
					.p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
						Panel.panel-list.ta-center(:header="props.data.nm_pessoa_fisica" style='position: relative')
							.ta-left
								p <b>Código:</b> {{ props.data.id }}
								p <b>Nome:</b> {{ props.data.nm_pessoa_fisica }}
								p <b>CPF:</b> {{ props.data.nr_cpf_f }}
								p <b>Data de nascimento:</b> {{ props.data.dt_nascimento_f }}
								p <b>Sexo:</b> {{ props.data.ie_sexo_f }}
								p <b>Telefone:</b> {{ props.data.nr_telefone_f }}
								a.whatsapp-link(v-if='props.data.nr_telefone' :href="`https://api.whatsapp.com/send?phone=${ props.data.nr_telefone_ws }`" target='_blank')
									i(class='jam jam-whatsapp')
								p(v-tooltip.top="props.data.ds_email" style='cursor: pointer;') <b>E-mail:</b> {{ props.data.ds_email_f }}
							.ta-right.p-buttonset.mt-3
								Button.mr-1.p-button-raised.p-button-rounded(icon="jam jam-write"
									@click="$router.push(`/paciente/salvar/${ props.data.id }/`)")
								Button.p-button-raised.p-button-rounded.p-button-danger(icon="jam jam-minus-circle"
									@click="dialogApagar = true; dialogApagar_data = props.data")

			Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

			Panel.datatable(header='Lista de pacientes')
				Paginator.mb-1(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
				DataTable(:value="list" @sort="onSort($event)" :sortField="order.field" :sortOrder="order.sortOrder")
					Column(headerStyle='width: 8%;' bodyStyle='text-align: center;' field='id' header='Código' sortable)
					Column(headerStyle='width: 20%;' field='nm_pessoa_fisica' header='Nome' sortable)
					Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='nr_cpf_f' header='CPF')
					Column(headerStyle='width: 10%;' bodyStyle='text-align: center;' field='dt_nascimento' header='Nascimento' sortable)
						template(#body='props')
								span(v-text="props.data.dt_nascimento_f")
					Column(headerStyle='width: 8%;' bodyStyle='text-align: center;' field='ie_sexo_f' header='Sexo')
					Column(headerStyle='width: 14%;' bodyStyle='text-align: center;' field='nr_telefone' header='Telefone')
						template(#body='props')
							span(v-text="props.data.nr_telefone_f")
							a.whatsapp-link(v-if='props.data.nr_telefone' :href="`https://api.whatsapp.com/send?phone=${ props.data.nr_telefone_ws }`" target='_blank')
								i(class='jam jam-whatsapp')
					Column(headerStyle='width: 15%;' header='E-mail')
						template(#body='props')
							span(v-tooltip.top="props.data.ds_email" v-text="props.data.ds_email_f")
					Column(headerStyle='width: 15%; text-align: center')
						template(#header) Ações
						template(#body='props')
							.ta-center
								Button.p-button-raised.p-button-rounded.p-button-help.mr-1(style="backgroundColor: #C16AF3; border: 1px solid #C16AF3;" v-if='waitingBoasVindasId !== props.data.id' v-tooltip.top="'Imprimir Boas Vindas'" icon="jam jam-printer" @click="printBoasVindas(props)")
								ProgressSpinner.dialogImprimir(v-if='waitingBoasVindasId === props.data.id' strokeWidth='6')
								Button.p-button-raised.p-button-rounded.p-button-warning.mr-1(v-if="props.data.cd_usuario_django != null" v-tooltip.top="'Resetar Senha'" icon="jam jam-key" @click="showResetarSenha(props)")
								Button.p-button-raised.p-button-rounded.mr-1(
									v-tooltip.top="'Editar'"
									icon="jam jam-write"
									@click="$router.push(`/paciente/salvar/${ props.data.id }/`)"
								)
								Button.p-button-raised.p-button-rounded.p-button-danger(
									v-tooltip.top="'Apagar'"
									icon="jam jam-minus-circle"
									@click="dialogApagar = true; dialogApagar_data = props.data"
								)
				Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

		ResetarSenha(v-if="displayResetarSenha" :display="displayResetarSenha" :data="dadosUsuario" @close="displayResetarSenha=false")

</template>

<style lang="scss">
.paciente-listar {
	.dataview {
		@media all and (min-width: 577px) {
			display: none;
		}
	}
	.datatable {
		@media all and (max-width: 576px) {
			display: none;
		}
		.cell {
			padding: 16px 0;
			text-align: center;
			&.ex  {  background-color: #e4f8e1; }
			&.a  { background-color: #faf3dd; }
		}
	}
	.dialogApagar {
		.p-progress-spinner {
			width: 29px;
			height: auto;
		}
	}
	.dialogImprimir {
		width: 29px;
		height: auto;
	}
	.whatsapp-link {
		margin-left: 8px;
		background-color: #0da64d;
		color: #fff;
		font-weight: 700;
		border-radius: 50%;
		padding: 4px;
		display: inline-block;
		vertical-align: middle;
		width: 28px;
		height: 28px;
		line-height: 24px;
		text-align: center;
		transition: 0.25s;
		&:hover {
			background-color: #0a7d3a;
		}
	}
}
.p-tooltip {
	max-width: none;
}
</style>

<script>
import ProgressBar from 'primevue/progressbar'
import DataView from 'primevue/dataview'
import Panel from 'primevue/panel'
import Paginator from 'primevue/paginator'
import DataTable from 'primevue/datatable'
import Column from 'primevue/column'
import InputText from 'primevue/inputtext'
import InputMask from 'primevue/inputmask'
import InputNumber from 'primevue/inputnumber'
import Button from 'primevue/button'
import Tooltip from 'primevue/tooltip'
import Dialog from 'primevue/dialog'
import ProgressSpinner from 'primevue/progressspinner'
import Methods from './Methods'
import { Paciente } from './../../middleware'
import moment from 'moment'
import wsConfigs from './../../middleware/configs'
import { fixTelefoneFormat } from './../../utils'
import ResetarSenha from '../Usuario/ResetarSenha'
const _ = require('lodash')
export default {
	beforeRouteEnter (to, from, next) {
			next(vm => {
				let routeName = vm.$route.path.split('/')[1]
				if ((from.path.split('/')[1] === routeName || from.path.split('/')[1] === 'paciente') && vm.$store.state.filters)
					vm.filters = {...vm.$store.state.filters}
				vm.$store.commit('clearFilters')
				vm.applyFilters()
			})
		},
		beforeRouteLeave (to, from, next) {
			let routeName = this.$route.path.split('/')[1]
			if (to.path.split('/')[1] === routeName)
				if (this.filters) this.$store.commit('setFilters', this.filters)
			next()
		},
	created () { },
	components: { ProgressBar, DataView, Panel, Paginator, DataTable, InputMask,
		Column, Button, Tooltip, Dialog, ProgressSpinner, InputText, ResetarSenha, InputNumber},
	directives: { tooltip: Tooltip },
	data () {
		return {
			list: [],
			windowInnerWidth: window.innerWidth,
			waiting: false,
			waitingApagar: false,
			dialogApagar: false,
			waitingBoasVindasId: 0,
			dialogApagar_data: {},
			filters: {
				nr_cpf: ' ',
				nm_pessoa_fisica: '',
				nr_telefone: ' ',
				dt_nascimento: ' ',
			},
			paginator: {
				page: this.$route.query.pg || 1,
				per_page: wsConfigs.paginator_perPage,
				count: 0
			},
			order: {
				field: 'nm_pessoa_fisica',
				sortOrder: 1
			},
			displayResetarSenha: false,
			dadosUsuario: ['Shauan'],
			getListDebounce: _.debounce(function (params, getFunction) {
					getFunction(params)
				}, 500, { leading: true }),
		}
	},
	methods: {
		getList (params) {
			this.waiting = true
			return Paciente.findAll(params).then(response => {
				if (response.status == 200) {
					this.paginator.count = response.data.count
					response.data.results.forEach(paciente => {
						paciente.dt_nascimento_f = moment(paciente.dt_nascimento).format('DD/MM/YYYY')
						paciente.ie_sexo_f = ({ M: 'Masculino', F: 'Feminino' })[paciente.ie_sexo]
						if (paciente.nr_telefone) paciente.nr_telefone_f = fixTelefoneFormat(paciente.nr_telefone)

						paciente.nr_telefone_ws = paciente.nr_telefone.match(/\d+/g).join('')
						if (paciente.nr_telefone_ws.substr(0, 2) === '55') {
							paciente.nr_telefone_ws = `${ paciente.nr_telefone_ws }`
						} else {
							paciente.nr_telefone_ws = `55${ paciente.nr_telefone_ws }`
						}

						if (paciente.nr_cpf) paciente.nr_cpf_f = paciente.nr_cpf.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
						if (paciente.ds_email) {
							let strMaxLength = 24
							paciente.ds_email_f = paciente.ds_email.length > strMaxLength ?
								`${ paciente.ds_email.substr(0, strMaxLength) }...` : paciente.ds_email
						}
					})
					this.list = response.data.results
				}else{
					this.list = []
				}
				this.waiting = false
				return true
			})
		},
		checkEmptyField (field) {
			if (_.toString(this.filters[field])?.length < 1) this.applyFilters()
		},
		applyFilters (page) {
            this.paginator.page = page || 1
			let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page }
			params.order = `${ this.order.sortOrder == -1 ? '-' : '' }${ this.order.field }`

			this.filters.nr_telefone = this.filters.nr_telefone.replace(/\D/g, "")

			if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
			Object.keys(this.filters).forEach((key) => {
				if(this.filters[key] && _.trim(this.filters[key])){
					if (key == 'nr_cpf') {
						params[key] = this.filters[key].replace(/\D/g, '')
					}
					else if (key.substr(0, 3) == 'dt_') {
						if (moment(this.filters[key], 'DD/MM/YYYY').isValid())
						params[key] = moment(this.filters[key], 'DD/MM/YYYY').format('YYYY-MM-DD')
					} else params[key] = this.filters[key]
				}
			})
			// Fake loading quando os filtros forem os mesmos
			if (_.isEqual(this.params, params)) {
				if (!this.waiting) {
					this.waiting = true
					setTimeout(() => this.waiting = false, 200)
				}
			} else {
				this.params = Object.assign({}, params)
				this.waiting = true
				this.getListDebounce(params, this.getList)
			}
		},
		onPage (ev) {
			this.applyFilters(ev.page + 1)
		},
		onSort(ev) {
			this.order.field = ev.sortField
			this.order.sortOrder = ev.sortOrder
			this.applyFilters()
		},
		remove () {
			this.waitingApagar = true
			Paciente.remove(this.dialogApagar_data.id).then(response => {
				this.waitingApagar = false
				if (([200, 201, 204]).includes(response.status)) {
					this.$toast.info('Paciente removido com sucesso', { duration: 3000 })
					this.dialogApagar = false
					this.applyFilters()
				} else if (response.data.detail) {
					if (typeof response.data.detail == 'string')
						this.$toast.error(response.data.detail, { duration: 3000 })
					else response.data.detail.forEach(msg => this.$toast.error(msg, { duration: 3000 }))
				}
			})
		},
		printBoasVindas(props) {
			console.log(props);

			Methods.imprimirBoasVindas(this, props.data.id)
		},
		showResetarSenha(data){
			this.displayResetarSenha = true;
			this.dadosUsuario = data;
		},
	}
}
</script>
